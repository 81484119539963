<template>
  <div>
    <div class="topheader">
      <div class="searchInput">
       寄件模板
      </div>
       <passWord />
    </div>
    <div class="contents">
      <div class="tableSearch">
          <el-form :inline="true" ref="searchForm" :model="searchForm" label-width="100px">
            <el-form-item label="订单状态" style="margin-bottom:10px">
              <el-select v-model="searchForm.status" clearable  placeholder="请选择">
                <el-option label="已上传" value="1"></el-option>
                <el-option label="已消费" value="2"></el-option>
                <el-option label="已发货" value="3"></el-option>
                <el-option label="已完成" value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="订单起止时间" style="margin-bottom:10px">
              <el-date-picker v-model="searchForm.date" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="订单号" style="margin-bottom:10px">
              <el-input v-model="searchForm.orderId" clearable  style="width:190px"
                placeholder="请输入会员ID"></el-input>
            </el-form-item>
            <el-form-item label="买家手机号" style="margin-bottom:10px">
              <el-input v-model="searchForm.buyerMobile" clearable  style="width:190px"
                placeholder="请输入会员手机"></el-input>
            </el-form-item>
             <el-form-item label="买家姓名" style="margin-bottom:10px">
              <el-input v-model="searchForm.buyerName" clearable  style="width:190px"
                placeholder="请输入会员手机"></el-input>
            </el-form-item>
            <el-form-item style="margin-bottom:10px">
              <el-input v-model="searchForm.goodsName" clearable  placeholder="商品名称/商品编号" style="width:350px"></el-input>
            </el-form-item>
            <el-button @click="getTopSearch" type="primary" icon="el-icon-search">查询</el-button>
            <el-button @click="getTopSearch">重置</el-button>
              <el-button @click="daochu" type="primary" :loading="loadingFile" icon="el-icon-search">导出订单</el-button>
          </el-form>
      </div>

    <div class="mainbox">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部" name="first"></el-tab-pane>
      <el-tab-pane label="待退款" name="second"></el-tab-pane>
      <el-tab-pane label="已退款" name="three"></el-tab-pane>
      <el-tab-pane label="已完成" name="four"></el-tab-pane>
      <el-tab-pane label="已取消" name="five"></el-tab-pane>
    </el-tabs>
     <div class="caozuoqu">
        <el-button type="primary" plain>批量作废</el-button>
        <el-button type="primary" plain @click="sendGood">快递发货</el-button>
        <el-button type="primary" plain :loading="loadingFile" @click="piliangdaochu" >批量导出订单</el-button>
        <el-button type="primary" plain>修改金额</el-button>
        <el-button type="primary" plain>转售后</el-button>
     </div>
       <el-table
        :data="tableData"
        border
        @selection-change="handleSelectionChange"
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        style="width: 100%">
        <el-table-column
          type="selection"
          width="50" header-align="center" align="center">
        </el-table-column>
        <el-table-column
          prop="goodsName"
          label="商品信息"
          width="300">
        </el-table-column>
        <el-table-column
          prop="buyerAccount"
          label="购买者"
          width="100">
        </el-table-column>
          <el-table-column
          prop="xiadanTime"
          label="下单时间" width="160">
        </el-table-column>
        <el-table-column
          prop="consigneeName"
          label="收货人" width="100">
        </el-table-column>
        <el-table-column
          prop="consigneePhone"
          label="联系电话" width="120">
        </el-table-column>
         <el-table-column
          prop="consigneeAddress"
          label="收货地址">
        </el-table-column>
        <el-table-column
      fixed="right"
      label="操作"
      width="100">
      <template slot-scope="scope">
        <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
        <el-button type="text" size="small">编辑</el-button>
      </template>
    </el-table-column>
      </el-table>
      <div class="paginations">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="changePage" :current-page="page"
            :total="total">
          </el-pagination>
      </div>
      </div>
    </div>

     <el-dialog title="上传快递单" :visible.sync="kuaiDiflag" :before-close="handleClose" width="600px">
      <el-form label-position="right">
        <el-upload
          class="upload-demo"
          drag
          action="string"
          :http-request="UploadImage"
          multiple style="margin: 0 auto; text-align:center">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传excel文件，且不超过10M</div>
        </el-upload>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getOrderList, exportOrder, upDelivery} from "@/api/orders";
import { MessageBox } from 'element-ui'
import passWord from "@/components/password";
let moment = require('moment');
export default {
  name: 'Orderss',
  components: { passWord },
  data() {
    return {
      searchForm: {
        date: '', 
        skintype: '',
        goodsName: '',
        buyerMobile: '',
        buyerName: '',
        status: '',
        start: '',
        end: '',
      },
      total: 0,
      page: 1,
      pageSize: 20,
      kuaiDiflag: false,
      loadingFile: false,
      activeName: 'first',
      tableData: [],
      multipleSelection: []
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getTopSearch(){
      var that = this
      var datas = that.searchForm
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.start = that.searchForm.date ? moment(that.searchForm.date[0]).format('YYYY-MM-DD') : '';
      datas.end = that.searchForm.date ? moment(that.searchForm.date[1]).format('YYYY-MM-DD') : '';
      getOrderList(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          var nodataArr = res.data.rows || []
          nodataArr.map((item)=>{
            item.xiadanTime = moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')
          })
          that.tableData = nodataArr
          that.total = res.data.totalRows
        }else{
          this.$message.error(res.message)
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val;
    },
    handleClose(){
      this.kuaiDiflag = false;
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
    daochu(){
      console.log('eee')
      var that = this
       MessageBox.alert('您是否确认要导出？', {
            confirmButtonText: '立即导出',
            type: 'warning'
          }).then(() => {
      var datas = that.searchForm
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.start = that.searchForm.date ? moment(that.searchForm.date[0]).format('YYYY-MM-DD') : '';
      datas.end = that.searchForm.date ? moment(that.searchForm.date[1]).format('YYYY-MM-DD') : '';
      this.loadingFile = true
      exportOrder(datas).then((res) => {
        if (res.code === 200) {
          that.loadingFile = false
          let link = document.createElement('a')
          link.href = res.data
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

        } else {
          this.$message({
            type: 'warning',
            message: '无记录可导出'
          })
        }
      })
          })
    },
    piliangdaochu(){
      var that = this
      if(that.multipleSelection.length==0){
        this.$message({
            type: 'warning',
            message: '请选择要导出的订单'
          })
        return
      }
      var datas = that.multipleSelection
      this.loadingFile = true
      exportOrder(datas).then((res) => {
        if (res.code === 200) {
          that.loadingFile = false
          let link = document.createElement('a')
          link.href = res.data
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

        } else {
          this.$message({
            type: 'warning',
            message: '无记录可导出'
          })
        }
      })
    },
    sendGood(){
      var that = this
      that.kuaiDiflag = true;
      // upDelivery(datas).then((res) => {
      //   if (res.code === 200) {

      //   } else {
      //     this.$message({
      //       type: 'warning',
      //       message: '无记录可导出'
      //     })
      //   }
      // })
    },
    handleCurrentChange(){
      
    },
    UploadImage(param){
      const formData = new FormData()
      formData.append('file', param.file)
      upDelivery(formData).then(res => {
      	console.log('上传图片成功')
        param.onSuccess() 

        if(res.code === 200){
          that.kuaiDiflag = false
        
        }

      })
    }
  },
  mounted(){
    this.getTopSearch()
  }
}
</script>

<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}

.el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
}
.caozuoqu{
  float:right;
  display: block;
  padding: 5px 0 10px;
  overflow: hidden;
}
</style>